import type { AppProps } from 'next/app'
import 'styles/globals.scss'
import React from 'react'

import dynamic from 'next/dynamic'
import { plomb98, satoshi } from 'utility/fonts'
import Head from 'next/head'
import { useMarketingCookies } from 'utility/marketingCookies'
import { GoogleTagManager } from '@next/third-parties/google'
import { AddressesProvider } from 'src/context/addresses'
import AmplitudeContextProvider from '../src/context/amplitude'
import { useClidsFromUrl } from '@/tracks/shortsClient'
import { useCoupon } from '@/tracks/useCoupon.hook'

const AuthModal = dynamic(() => import('src/components/auth/AuthModal'), { ssr: false })
const ToastContainer = dynamic(() => import('react-toastify').then((mod) => mod.ToastContainer), {
    ssr: false,
})
const AuthProvider = dynamic(() => import('src/context/auth').then((res) => res.AuthProvider), {
    ssr: true,
})

function SQApp({ Component, pageProps }: AppProps) {
    useMarketingCookies()
    useClidsFromUrl()
    useCoupon()

    return (
        <>
            <Head>
                <link href="/favicon-black.png" rel="icon" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
                />
                <meta
                    name="google-site-verification"
                    content="opWJxl-tI5HGgdyZqFQFr2DcY7cRBSfA0CduEIZ8pkA"
                />
                <meta
                    name="facebook-domain-verification"
                    content="muef0pz865dpbqpeeuqw8xzax9n1ec"
                />
                <link rel="preconnect" href="https://res.cloudinary.com" />
                <link rel="preconnect" href="https://googletagmanager.com" />
                <link rel="preconnect" href="https://js.hs-scripts.com" />
            </Head>
            <main className={`${satoshi.className} ${plomb98.variable} ${satoshi.variable}`}>
                <AuthProvider>
                    <AddressesProvider>
                        <AmplitudeContextProvider>
                            <Component {...pageProps} />
                            <AuthModal />
                        </AmplitudeContextProvider>
                    </AddressesProvider>
                </AuthProvider>
                <GoogleTagManager gtmId="GTM-P223Z6R" />
            </main>
            <ToastContainer />
        </>
    )
}

export default SQApp
