'use client'

import Cookies from 'js-cookie'

import { useEffect } from 'react'

export function useClidsFromUrl() {
    useEffect(() => {
        const sqclidUrl = extractFromUrl(window.location.search, 'sqclid')
        if (sqclidUrl) {
            Cookies.set('_sqclid', sqclidUrl)
        }

        const fbclidUrl = extractFromUrl(window.location.search, 'fbclid')
        if (fbclidUrl) {
            Cookies.set('_fbclid', fbclidUrl)
        }
    }, [])
}

function extractFromUrl(query: string, key: string) {
    const params = new URLSearchParams(query)
    return params.get(key)
}
