import { buildHttpClient } from './http'

export const BASE_URL = (() => {
    let env = process.env.NEXT_PUBLIC_SUPPLY_URL || 'https://qdra.io/api/'
    // Fixes the trailing slash
    if (env.endsWith('/')) {
        env = env.slice(0, -1)
    }

    // Removes the version from the URL
    if (env.endsWith('/v1')) {
        env = env.slice(0, -3)
    }

    return env
})()

const http = buildHttpClient(BASE_URL)

export default http
