import { AuthState } from 'src/@core/auth/auth.model'
import Cookies from 'js-cookie'
import { httpPreview as authHttpPreview } from 'src/@infra/http/auth.http'

export function refreshTokenPreview(refreshToken: string) {
    return authHttpPreview.post<
        { refresh_token: string; client_id: string },
        {
            token: AuthState
        }
    >('refreshTokenPublic', {
        refresh_token: refreshToken,
        client_id: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT || 'ssr',
    })
}

export type OTPProvider = 'whatsapp' | 'sms'

export function requestOTPPreview(number: string, provider: OTPProvider) {
    return authHttpPreview.get(`login/${number}/${provider}`)
}

export function confirmOTPPreview(number: string, token: string) {
    return authHttpPreview.get<AuthState>(`login/${number}/verify?token=${token}`)
}

const EXPIRES_IN_DAYS = 180
export const COOKIE_ACCESS_TOKEN_KEY = 'sq-access-token'
export const COOKIE_REFRESH_TOKEN_KEY = 'sq-refresh-token'

function storeAuth(auth: AuthState): void {
    Cookies.set(COOKIE_ACCESS_TOKEN_KEY, auth.access_token, {
        path: '/',
        expires: EXPIRES_IN_DAYS,
    })
    Cookies.set(COOKIE_REFRESH_TOKEN_KEY, auth.refresh_token, {
        path: '/',
        expires: EXPIRES_IN_DAYS,
    })
}

function cleanAuth(): void {
    Cookies.remove(COOKIE_ACCESS_TOKEN_KEY)
    Cookies.remove(COOKIE_REFRESH_TOKEN_KEY)
}

function getAuth(): AuthState | null {
    const access_token = Cookies.get(COOKIE_ACCESS_TOKEN_KEY)
    const refresh_token = Cookies.get(COOKIE_REFRESH_TOKEN_KEY)

    if (!access_token || !refresh_token) {
        return null
    }

    return { access_token, refresh_token }
}

const authGateway = { storeAuth, cleanAuth, getAuth, refreshTokenPreview }

export default authGateway
