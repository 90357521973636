'use client'

import { fetchBairroLogradouros, fetchBairros } from '@/results/requests'
import React, { createContext, useCallback, useState } from 'react'
import { BairroLogradouroType, RegionType } from 'src/@core/search/search.model'

export const AddressesContext = createContext({
    regions: [] as RegionType[],
    logradouros: [] as BairroLogradouroType[],
    fetchAddresses: (): void => {
        throw new Error('Make sure to wrap your component with "AddressesProvider"')
    },
})

export const AddressesProvider = ({ children }: { children: React.ReactNode }) => {
    const [regions, setRegions] = useState<RegionType[]>([])
    const [logradouros, setLogradouros] = useState<BairroLogradouroType[]>([])

    const fetchAddresses = useCallback(() => {
        if (!regions.length) {
            fetchBairros().then(setRegions)
        }
        if (!logradouros.length) {
            fetchBairroLogradouros().then(setLogradouros)
        }
    }, [regions, logradouros])

    return (
        <AddressesContext.Provider value={{ regions, logradouros, fetchAddresses }}>
            {children}
        </AddressesContext.Provider>
    )
}
