'use client'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

function extractFromUrl(query: string, key: string) {
    const params = new URLSearchParams(query)
    return params.get(key)
}

export function useCoupon(): string | null {
    const [coupon, setCoupon] = useState<string | null>(null)

    useEffect(() => {
        const couponCookie = Cookies.get('cupom')
        if (!couponCookie) {
            const coupon = extractFromUrl(window.location.search, 'cupom')
            if (coupon) {
                Cookies.set('cupom', coupon)
                setCoupon(coupon)
            }
        } else {
            setCoupon(couponCookie)
        }
    }, [])
    return coupon
}
