import { jwtDecode } from 'jwt-decode'
import authGateway from './auth.gateway'
import { AuthState } from './auth.model'

const storeAuth = (auth: AuthState) => {
    return authGateway.storeAuth(auth)
}

const cleanAuth = () => {
    return authGateway.cleanAuth()
}

const getAuthPreview = async (): Promise<AuthState> => {
    const auth = authGateway.getAuth()

    if (!auth?.access_token) {
        return Promise.reject('Unauthenticated')
    }

    if (isTokenExpired(auth.access_token)) {
        const refreshToken = await authGateway.refreshTokenPreview(auth.refresh_token)
        storeAuth(refreshToken.token)
        return refreshToken.token
    }

    return auth
}

const isTokenExpired = (token: string, now: () => number = Date.now): boolean => {
    try {
        const timeSkew = 3 // 3s
        const payload = jwtDecode(token)
        const clockTimestamp = Math.floor(now() / 1000) + timeSkew
        return !payload.exp || payload.exp < clockTimestamp
    } catch (error) {
        return true
    }
}

export { storeAuth, cleanAuth, isTokenExpired, getAuthPreview }
