import { BairroLogradouroType, LogradouroType, RegionType } from 'src/@core/search/search.model'
import supplyHttp from '../../@infra/http/supply.http'

export async function fetchBairros(): Promise<RegionType[]> {
    return supplyHttp
        .get<RegionType[] | null>('v1/addresses/count-bairros')
        .then((res) => res || [])
        .catch(() => [])
}

export async function fetchLogradouros(): Promise<LogradouroType[]> {
    return supplyHttp
        .get<LogradouroType[] | null>('v1/addresses/count-logradouros')
        .then((res) => res || [])
        .catch(() => [])
}

export async function fetchBairroLogradouros(): Promise<BairroLogradouroType[]> {
    return supplyHttp
        .get<BairroLogradouroType[] | null>('v1/addresses/count-bairro-logradouro')
        .then((res) => res || [])
        .catch(() => [])
}
